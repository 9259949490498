import { ChangePasswordArgs, ChangePasswordResponse, CheckEmailVerificationBody, IUser, ResetPasswordArgs, ResetPasswordResponse, SendResetPasswordArgs, SendResetPasswordResponse, SignInUserArgs, SignInUserResponse, SignUpUserArgs, SignUpUserResponse, VerifyEmailArgs, VerifyEmailResponse } from "types";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";

const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};


export const Login = (data: SignInUserArgs) => {
    return api.create<SignInUserArgs, SignInUserResponse>(url.POST_LOGIN, data).then((response) => {
        api.setAuthorization(response.token)
        return response
    });
}
export const GoogleLogin = (token: string) => {
    return api.create<{ token: string }, SignInUserResponse>(url.POST_LOGIN_GOOGLE, { token })
        .then((response) => {
            api.setAuthorization(token)
            return response
        });
}
export const Register = (data: SignUpUserArgs) => api.create<SignInUserArgs, SignUpUserResponse>(url.POST_REGISTER, data);

export const SendPasswordReset = (data: SendResetPasswordArgs) => api.create<SendResetPasswordArgs, SendResetPasswordResponse>(url.POST_SEND_RESET_PASSWORD, data);

export const ResetPassword = (data: ResetPasswordArgs) => api.put<ResetPasswordArgs, ResetPasswordResponse>(url.POST_RESET_PASSWORD, {}, data);
export const VerifyEmail = (data: VerifyEmailArgs) => api.create<VerifyEmailArgs, VerifyEmailResponse>(url.VERIFY_EMAIL, data);

export const RecoverSession = (data: string) => {
    if (data)
        api.setAuthorization(data)
    return api.get<{}, { user: IUser }>(url.DECODE_TOKEN);
}

export const ChangePassword = (data: ChangePasswordArgs) => api.create<ChangePasswordArgs, ChangePasswordResponse>(url.CHANGE_PASSWORD, data);

export const CheckEmailVerification = (data: CheckEmailVerificationBody) => api.create<{}, { isVerified: boolean }>(url.CHECK_EMAIL_VERIFICATION, data);

export const ResendVerificationCode = (data: CheckEmailVerificationBody) => api.create<{}, {}>(url.RESEND_EMAIL_VERIFICATION, data);