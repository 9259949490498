import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { Link, useParams } from "react-router-dom";
import L, { LatLng, divIcon } from "leaflet";
import "./custom-marker.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/loader/loader";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import CheckIcon from "../../../../assets/images/check_circle.svg";
import EditIcon from "../../../../assets/images/svg/pencil-fill.svg";
import TrashIcon from "../../../../assets/images/svg/trash-fill.svg";
import PetIcon from "../../../../assets/images/svg/pet.svg";
import CarIcon from "../../../../assets/images/svg/car.svg";
import RoomIcon from "../../../../assets/images/svg/room.svg";
import RuleIcon from "../../../../assets/images/svg/rule.svg";
import PriceIcon from "../../../../assets/images/svg/price.svg";
import MailAgent from "../../../../assets/images/mail-agent.png";
import ParkingIcon from "../../../../assets/images/svg/parking.svg";
import YearsBuildIcon from "../../../../assets/images/svg/rewind.svg";
import LocationIcon from "../../../../assets/images/svg/location.svg";
import BathRoomIcon from "../../../../assets/images/svg/bathroom.svg";
import MapGoogle from "../../../Maps/GoogleMaps/GoogleMapIntegration";
import WhatsAppAgent from "../../../../assets/images/whatsapp-agent.png";
import {
  GetProperty,
  UpdateStateProperty,
  DeleteProperty,
} from "../../../../service/properties";
import Slidewithindicator from "../../../BaseUi/UiCarousel/CarouselTypes/slidewithindicator";
import {
  TileLayer,
  Marker,
  Popup,
  MapContainer,
  useMapEvents,
  useMap,
} from "react-leaflet";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Label,
  Row,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
type Coordinates = {
  lat: number;
  lng: number;
};

const LocationMarker = ({ coord }: { coord: Coordinates }) => {
  const mapRefresh = useMap();
  const customIcon = divIcon({
    className: "custom-marker-icon",
    html: `<i class="mdi mdi-map-marker icon-large"></i>`,
    iconSize: [41, 41],
  });

  console.log("valor de coordenadas!!!", coord);
  const [position, setPosition] = useState<LatLng | null>(null);

  useEffect(() => {
    console.log("Entre aqui!!!");
    setPosition(new LatLng(coord.lat, coord.lng));
    mapRefresh.setView([coord.lat, coord.lng], 13);
  }, [coord]);

  const map = useMapEvents({
    click() {
      map.locate();
    },
    locationfound(e) {
      console.log("evento maps", e.latlng);
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={coord} icon={customIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

const PropertiesDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  console.log("valor de id!!@@@###", _id);
  const rows: any = [];
  const [loading, setLoading] = useState(false);
  const [detailsProperty, setDetailsProperty] = useState<any>();
  const [detailsCharacteristics, setDetailsCharacteristics] = useState<any>([]);
  const [detailsAmenities, setDetailsAmenities] = useState<any>([]);
  const [detailsIncludedServices, setDetailsIncludedServices] = useState<any>(
    []
  );
  const [detailsSurroundings, setDetailsSurroundings] = useState<any>([]);
  const [detailsImages, setDetailsImages] = useState<any>([]);
  const [propertyStatus, setPropertyStatus] = useState<any>([]);
  const [formValues, setFormValues] = useState({
    status: { label: "Activo", value: true },
    slow: false,
    sold: false,
  });

  const characteristicsToDisplay = [
    { key: "airConditioning", label: "Aire acondicionado" },
    { key: "garden", label: "Jardín / Patio" },
    { key: "grill", label: "Parrilla" },
    { key: "microwave", label: "Microondas" },
    { key: "rampOrElevator", label: "Rampa o Ascensor" },
    { key: "refrigerator", label: "Refrigerador" },
    { key: "terrace", label: "Terraza" },
    { key: "toaster", label: "Tostadora" },
    { key: "walkingCloset", label: "Walk-in Closet" },
    { key: "waterHeater", label: "Calentador de agua" },
    { key: "coffeeMachine", label: "Maquina de café" },
    { key: "dryer", label: "Secadora" },
    { key: "kitchen", label: "Cocina" },
    { key: "washingMachine", label: "Lavadora" },
    { key: "panoramicView", label: "Vista panoramica" },
    { key: "furnished", label: "Amoblado / Semi Amoblado" },
    { key: "indoorParking", label: "Parqueo con techo" },
    { key: "petFriendly", label: "Pet Friendly" },
  ];

  const amenitiesToDisplay = [
    { key: "BBQranch", label: "Rancho BBQ" },
    { key: "gym", label: "Gimnasio" },
    { key: "petArea", label: "Área de mascotas" },
    { key: "pool", label: "Piscina" },
    { key: "security", label: "Seguridad 24/7" },
    { key: "sauna", label: "Sauna" },
    { key: "tennisCourt", label: "Cancha de tenis" },
    { key: "playground", label: "Parque infantil" },
  ];

  const includedServicesToDisplay = [
    { key: "electricity", label: "Servicio electrico" },
    { key: "internet", label: "Internet" },
    { key: "maintenanceFee", label: "Cuota de mantenimiento" },
    { key: "tv", label: "TV por cable" },
    { key: "water", label: "Agua" },
  ];

  const includedSurroundings = [
    { key: "hospital", label: "Hospital" },
    { key: "malls", label: "Centros comerciales" },
    { key: "publicTransport", label: "Transporte publico" },
    { key: "school", label: "Escuela" },
    { key: "supermarkets", label: "Supermercado" },
  ];

  const options = [
    {
      label: "Activo",
      value: true,
    },
    {
      label: "Desactivado",
      value: false,
    },
  ];

  const getProperty = async () => {
    if (!_id) return
    const response: any = await GetProperty(_id);
    console.log("valor de response!!!!", response);
    setDetailsProperty(response);

    const images = response.images.map((item: any, index: number) => ({
      altText: " ",
      caption: " ",
      key: index,
      src: item.url,
    }));

    const statusProperties = {
      status: response.status.status
        ? { label: "Activo", value: true }
        : { label: "Desactivado", value: false },
      slow: response.status.slow ? true : false,
      sold: response.status.sold ? true : false,
    };

    console.log("status properties", statusProperties);

    setFormValues(statusProperties);

    setDetailsImages((prevImages: any) => [...prevImages, ...images]);

    const trueCharacteristics = Object.entries(
      response.additionalCharacteristics
    )
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueCharacteristics.pop();

    const trueAmenities = Object.entries(response.amenities)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueAmenities.pop();

    const trueServices = Object.entries(response.includedServices)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueServices.pop();

    const trueSurroundings = Object.entries(response.surroundings)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueSurroundings.pop();

    const selectedTrueCharacteristics = characteristicsToDisplay.filter(
      (characteristic) => trueCharacteristics.includes(characteristic.key)
    );

    const selectedTrueAmenities = amenitiesToDisplay.filter((characteristic) =>
      trueAmenities.includes(characteristic.key)
    );

    const selectedTrueServices = includedServicesToDisplay.filter(
      (characteristic) => trueServices.includes(characteristic.key)
    );

    const selectedTrueSurroundings = includedSurroundings.filter(
      (characteristic) => trueSurroundings.includes(characteristic.key)
    );

    setDetailsCharacteristics(selectedTrueCharacteristics);
    setDetailsAmenities(selectedTrueAmenities);
    setDetailsIncludedServices(selectedTrueServices);
    setDetailsSurroundings(selectedTrueSurroundings);
    setLoading(false);
  };

  const deleteProperty = async (id: any) => {
    setLoading(true);
    console.log("valor del id del Agente!!!", id);
    const response = await DeleteProperty(id);
    setLoading(false);
    navigate("/pages-properties");
  };

  const updateStateProperty = async () => {
    setLoading(true);
    console.log("VALOR DE DATA A ACTUALIZAR", formValues);
    const states = {
      status: formValues.status.value,
      slow: formValues.slow,
      sold: formValues.sold,
    };
    const response = await UpdateStateProperty(_id, states);
    setLoading(false);
    console.log("respuesta actualizar estado de la propiedad", response);
    console.log("valor de estados PROPERTY!!!", states);
  };

  const handleRadioChange = (event: any) => {
    const { value } = event.target;
    console.log("valor de value", value);
    setFormValues({
      ...formValues,
      slow: value === "slow" ? true : false,
      sold: value === "sold" ? true : false,
    });
  };

  useEffect(() => {
    setLoading(true);
    getProperty();
    // setRefresh(false);
  }, []);

  document.title = "Detalle de una propiedad";
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Detalle de una propiedad" pageTitle="Pages" />

            <Row>
              <Col lg={8}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">
                      {detailsProperty?.detail?.title || "Título no disponible"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Col xl={12}>
                      <div className="live-preview">
                        {detailsProperty &&
                          detailsImages.length ===
                          detailsProperty.images.length && (
                            <Slidewithindicator items={detailsImages} />
                          )}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="grid-container-details">
                        <div>
                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={RoomIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Habitaciones</b>
                              </div>
                              <b>{detailsProperty.detail.numberOfRooms}</b>
                            </div>
                          )}

                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={ParkingIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Puestos de estacionamiento</b>
                              </div>
                              <b>
                                {detailsProperty.detail.numberOfParkingSpaces}
                              </b>
                            </div>
                          )}

                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={RuleIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Tamaño</b>
                              </div>
                              <b>{detailsProperty.detail.surface}m²</b>
                            </div>
                          )}

                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={LocationIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Locación</b>
                              </div>
                              <b>{detailsProperty.location.direction}</b>
                            </div>
                          )}
                        </div>

                        <div>
                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={BathRoomIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Baños</b>
                              </div>
                              <b>{detailsProperty.detail.numberOfBathrooms}</b>
                            </div>
                          )}

                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={YearsBuildIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Año de construcción</b>
                              </div>
                              <b>{detailsProperty.detail.constructionYear}</b>
                            </div>
                          )}

                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={PetIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Pet Friendly</b>
                              </div>
                              <b>
                                {detailsProperty.amenities.petArea
                                  ? "Sí"
                                  : "No"}
                              </b>
                            </div>
                          )}

                          {detailsProperty && (
                            <div className="details-container-info">
                              <div>
                                <img
                                  src={PriceIcon}
                                  className="img-fluid icons-details"
                                  alt="user-pic"
                                />
                                <b>Precio</b>
                              </div>
                              <b>{detailsProperty.detail.priceInDollars}$</b>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>

                    {detailsCharacteristics.length > 0 && (
                      <Col md={12}>
                        <div>
                          <h5 className="mb-3">Características adicionales</h5>
                          <div className="grid-container">
                            {detailsCharacteristics.map(
                              (row: any, rowIndex: number) => (
                                <div className="form-check mb-3">
                                  <img
                                    src={CheckIcon}
                                    className="img-fluid form-check-input"
                                    style={{
                                      width: 18,
                                      height: 18,
                                      border: "none",
                                    }}
                                    alt="user-pic"
                                  />
                                  <Label
                                    className="form-check-label"
                                    for={`formCheck`}
                                  >
                                    {row.label}
                                  </Label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    )}

                    {detailsAmenities.length > 0 && (
                      <Col md={12}>
                        <div>
                          <h5 className="mb-3">Amenidades</h5>
                          <div className="grid-container">
                            {detailsAmenities.map(
                              (row: any, rowIndex: number) => (
                                <div className="form-check mb-3">
                                  <img
                                    src={CheckIcon}
                                    className="img-fluid form-check-input"
                                    style={{
                                      width: 18,
                                      height: 18,
                                      border: "none",
                                    }}
                                    alt="user-pic"
                                  />
                                  <Label
                                    className="form-check-label"
                                    for={`formCheck`}
                                  >
                                    {row.label}
                                  </Label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    )}

                    {detailsIncludedServices.length > 0 && (
                      <Col md={12}>
                        <div>
                          <h5 className="mb-3">Servicios incluidos</h5>
                          <div className="grid-container">
                            {detailsIncludedServices.map(
                              (row: any, rowIndex: number) => (
                                <div className="form-check mb-3">
                                  <img
                                    src={CheckIcon}
                                    className="img-fluid form-check-input"
                                    style={{
                                      width: 18,
                                      height: 18,
                                      border: "none",
                                    }}
                                    alt="user-pic"
                                  />
                                  <Label
                                    className="form-check-label"
                                    for={`formCheck`}
                                  >
                                    {row.label}
                                  </Label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    )}

                    {detailsSurroundings.length > 0 && (
                      <Col md={12}>
                        <div>
                          <h5 className="mb-3">Cercanías</h5>
                          <div className="grid-container">
                            {detailsSurroundings.map(
                              (row: any, rowIndex: number) => (
                                <div className="form-check mb-3">
                                  <img
                                    src={CheckIcon}
                                    className="img-fluid form-check-input"
                                    style={{
                                      width: 18,
                                      height: 18,
                                      border: "none",
                                    }}
                                    alt="user-pic"
                                  />
                                  <Label
                                    className="form-check-label"
                                    for={`formCheck`}
                                  >
                                    {row.label}
                                  </Label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    )}

                    <Col md={12}>
                      <Col lg={12} md={6} style={{ marginTop: 20 }}>
                        <h5 className="mb-3">Locación</h5>
                        <div className="mb-3">
                          {detailsProperty && (
                            <MapGoogle
                              coords={{
                                lat: detailsProperty.location.lat,
                                lng: detailsProperty.location.lng,
                              }}
                            />
                            //     <MapContainer center={{ lat: detailsProperty.location.lat, lng: detailsProperty.location.lng }} zoom={13} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
                            //     <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            //                 <LocationMarker coord={{ lat: detailsProperty.location.lat, lng: detailsProperty.location.lng }} />
                            // </MapContainer>
                          )}
                        </div>
                      </Col>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card>
                  <CardBody className="p-4 text-center">
                    <div className="mx-auto avatar-md mb-3">
                      <img
                        src={
                          detailsProperty && detailsProperty.detail?.agent?.image
                        }
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-1">
                      {detailsProperty && detailsProperty.detail?.agent?.fullName}
                    </h5>
                    <p className="text-muted mb-0">
                      {detailsProperty && detailsProperty.detail?.agent?.email}
                    </p>
                  </CardBody>
                  <div className="card-footer text-center">
                    <ul className="list-inline mb-0">
                      {detailsProperty && (
                        <li className="list-inline-item">
                          <Link
                            to={`mailto:${detailsProperty.detail?.agent?.email}`}
                            className="lh-1 align-middle link-success"
                          >
                            <img src={MailAgent} />
                          </Link>
                        </li>
                      )}
                      {detailsProperty && (
                        <li className="list-inline-item">
                          <Link
                            to={`https://wa.me/${detailsProperty.detail?.agent?.phone}`}
                            className="lh-1 align-middle link-success"
                          >
                            <img src={WhatsAppAgent} />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </Card>
                <Card>
                  <CardBody className="p-4">
                    <Row>
                      <h5 style={{ padding: 0 }} className="card-title mb-2">
                        Estatus de la propiedad
                      </h5>
                      <Select
                        options={options}
                        className="p-0"
                        style={{ padding: 0 }}
                        value={formValues.status}
                        onChange={(selectedOption: any) =>
                          setFormValues({
                            ...formValues,
                            status: selectedOption,
                          })
                        }
                        placeholder="Selecciona un estatus"
                      />
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Button
                        onClick={updateStateProperty}
                        className="buttons-details-properties"
                        style={{
                          backgroundColor: "#1BCEA3",
                          border: "1px solid #1BCEA3",
                        }}
                      >
                        Aplicar cambios
                      </Button>
                      <p className="text-muted mt-2 p-0 mb-0">
                        Si deshabilitas el estatus de la propiedad no será
                        mostrada en el sitio web.
                      </p>
                      {!formValues.status.value && (
                        <>
                          <p>Motivo de la desactivación</p>
                          <ListGroup>
                            <ListGroupItem
                              style={{ border: "none" }}
                              tag="label"
                            >
                              <Input
                                type="radio"
                                value="slow"
                                defaultChecked
                                name="propertyAction"
                                checked={formValues.slow}
                                onChange={handleRadioChange}
                                className="form-check-input fs-14 mt-0 align-middle me-1"
                              />
                              Pausar por un momento
                            </ListGroupItem>
                            <ListGroupItem
                              style={{ border: "none" }}
                              tag="label"
                            >
                              <Input
                                value="sold"
                                type="radio"
                                name="propertyAction"
                                checked={formValues.sold}
                                onChange={handleRadioChange}
                                className="form-check-input fs-14 mt-0 align-middle me-1"
                              />
                              La propiedad fue vendida
                            </ListGroupItem>
                          </ListGroup>
                        </>
                      )}
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="p-4 text-center">
                    <Row style={{ marginBottom: 10 }}>
                      <Button
                        color="primary"
                        onClick={() =>
                          navigate(`/pages-properties-form/${_id}`)
                        }
                        className="buttons-details-properties"
                      >
                        <img
                          src={EditIcon}
                          className="img-fluid images-details-properties"
                          alt="user-pic"
                        />{" "}
                        Editar
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        className="buttons-details-properties"
                        onClick={() => deleteProperty(_id)}
                        style={{
                          backgroundColor: "red",
                          border: "1px solid red",
                        }}
                      >
                        <img
                          src={TrashIcon}
                          className="img-fluid images-details-properties"
                          alt="user-pic"
                        />{" "}
                        Eliminar
                      </Button>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default PropertiesDetails;
