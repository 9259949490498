import React, { useState } from 'react';
import { Button, Form, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ContractStatus, IContract } from 'types/api/contracts';
import TemplatePreviewContracts from '../Template';
import { useNavigate } from 'react-router-dom';
import { CreateTenantRating } from "service/rating"
import { useFormik } from 'formik';
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';
import { useAppSelector } from 'slices/hooks';

const ratingInitValue = {
  comment: "",
  rating: 0
}

const FinishedContracts = () => {
  document.title = "Contratos finalizados | Partarentals";
  const { user } = useAppSelector(state => state.Profile)
  const navigate = useNavigate()
  const [modal_center, setmodal_center] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IContract | null>(null);
  const [reload, setReload] = useState<(() => void) | undefined>(undefined);
  function togRatingModal() {
    setmodal_center(!modal_center);
  }

  const [modal_declined, setmodal_declined] = useState<boolean>(false);
  function decline_payment() {
    setmodal_declined(!modal_declined);
  }

  const ratingValidation = useFormik({
    initialValues: ratingInitValue,
    onSubmit: (values) => {
      handleRating(values.comment, values.rating)
    }
  })

  const handleRating = async (comment: string, rating: number) => {
    try {
      if (!selectedContract) return
      if (selectedContract.owner._id !== user?._id)
        await CreateTenantRating({
          owner: selectedContract.owner._id,
          tenant: selectedContract.tenant._id,
          ratingType: "owner_to_tenant",
          comment,
          rating
        })

      if (selectedContract.tenant._id !== user?._id)
        await CreateTenantRating({
          owner: selectedContract.owner._id,
          tenant: selectedContract.tenant._id,
          ratingType: "tenant_to_owner",
          comment,
          rating
        })
      ratingValidation.setValues(ratingInitValue)
      togRatingModal()
      toast.success("Valoración realizada con éxito")
    } catch (error) {
      console.log(error)
    } finally {
      reload && reload()
    }
  }



  return (
    <React.Fragment>
      <TemplatePreviewContracts
        headerTitle='Contratos inactivos'
        statusFilter={[ContractStatus.COMPLETED]}
        contractType="contracts"
        itemsActions={[
          { label: "Ver detalle", action: (v) => { navigate(`/contracts-details/${v._id}`) } },
          {
            label: "Valorar", action: (v, reload) => {
              setSelectedContract(v)
              togRatingModal()
              setReload(() => reload);
            },
            validation: (v) => (v.owner._id === user?._id || v.tenant._id === user?._id)
          },
        ]} />
      {/* Vertically Centered */}
      <Modal
        isOpen={modal_declined}
        toggle={() => {
          decline_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
            <form action="">
              <div className="mb-3 text-start">
                <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
                <select className="form-select mb-3" aria-label="Default select example">
                  <option value="pago_incompleto">Pago incompleto</option>
                </select>
              </div>
              <div className="mb-3 text-start">
                <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea>
              </div>
              <div className="hstack gap-2 justify-content-center">
                <Button color="light" onClick={() => setmodal_declined(false)}>Cancelar</Button>
                <Button color="danger" onClick={() => console.log("Funciona")}>Rechazar</Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      {/* Vertically Centered */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          togRatingModal();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="bx bxs-star text-primary" style={{ fontSize: "60px" }}></i>
          <Form onSubmit={(e) => {
            e.preventDefault()
            ratingValidation.handleSubmit(e)
          }}>
            <div className="mt-4">
              <h4 className="mb-2 fw-bold">Valora al {" "}
                {user?._id === selectedContract?.tenant._id && "Propietario"}
                {user?._id === selectedContract?.owner._id && "Inquilino"}
              </h4>
              <p className="text-muted mb-3">Esta valoración será publica.</p>
              <div>
                <h5 className='d-flex justify-content-center fw-bold text-align-center'>Califique al {" "}
                  {user?._id === selectedContract?.tenant._id && "Propietario"}
                  {user?._id === selectedContract?.owner._id && "Inquilino"}
                  {" "}con estrellas</h5>
                <Rating
                  initialValue={ratingValidation.values.rating}
                  size={30}
                  allowHover={false}
                  allowFraction={true}
                  onClick={(value) => {
                    ratingValidation.setValues({ ...ratingValidation.values, rating: value })
                  }}
                />{/*Aquí va la barra de rating, no supe colocarla*/}
                <span>{ratingValidation.values.rating} estrellas</span>
              </div>
              <div>
                <h5 className='d-flex justify-content-start fw-bold'>Comentario</h5>
                <textarea
                  className='form-control mb-3'
                  id="comment"
                  name="comment"
                  value={ratingValidation.values.comment}
                  onChange={ratingValidation.handleChange}
                ></textarea>
              </div>
              <div className="hstack gap-2 justify-content-center">
                <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                <Button color="success" type='submit'>Valorar</Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default FinishedContracts;