//@ts-ignore

import * as googleLibPhoneNumber from "google-libphonenumber";
import { allRegionMap } from "./region";


const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
const PNF = googleLibPhoneNumber.PhoneNumberFormat;

export const phoneLoginRegex =
  /^[\]?[(]?[0-9]{3}[)]?[-\s\]?[0-9]{0,3}[-\s\]?[0-9]{4,8}$/;

interface PhoneUtilities {
  timeZone?: string;
  countryCode?: string;
  state?: string;
  phoneNumber?: number;
  region?: string;
  formattedPhoneNumber?: string;
  nationalNumber?: string;
  e164Number?: string;
  isValid: boolean;
}
export const phoneUtilities = (
  phoneNumberString: string,

): PhoneUtilities => {
  try {

    const phoneNumber = phoneUtil.parseAndKeepRawInput(
      phoneNumberString,
    ); // 'US' es el código de país predeterminado (cambiar según tu necesidad)
    const regionCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
    if (phoneUtil.isValidNumber(phoneNumber)) {
      const info: PhoneUtilities = {
        timeZone: allRegionMap.find(el => el.Code === regionCode)?.Timezone ?? "", // TODO: Should install moment-timezone'
        countryCode: `+${phoneNumber.getCountryCode()}`,
        phoneNumber: phoneNumber.getNationalNumber(),
        region: phoneUtil
          .getRegionCodeForNumber(phoneNumber)
          ?.toLocaleLowerCase(),
        formattedPhoneNumber: phoneUtil.format(phoneNumber, PNF.INTERNATIONAL),
        nationalNumber: phoneUtil.format(phoneNumber, PNF.NATIONAL),
        e164Number: phoneUtil.format(phoneNumber, PNF.E164),
        isValid: true,
      };

      return info;
    } else {
      return {
        isValid: false,
      };
    }
  } catch (error) {
    return {
      isValid: false,
    };
  }
};
