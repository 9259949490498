import React, { useCallback, useEffect, useState } from 'react';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Nav, NavItem, Row, TabContent, TabPane, NavLink, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { BankTransferDetails, CashDetails, ICreatePaymentMethod, IPaymentMethod, PaymentMethodType, PaypalDetails, SimpeDetails } from 'types/api/paymentMethod';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { GetCurrencies } from 'service/currencies';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import Select from 'react-select';
import { GetOwnerPaymentMethods, CreatePaymentMethod, UpdatePaymentMethod } from 'service/paymentMethods';
import { useAppSelector } from 'slices/hooks';
import { toast } from 'react-toastify';

type DetailsKeys =
  "accountName" |
  "phoneNumber" |
  "accountNumber" |
  "bankName" |
  "email" |
  "available" |
  "swiftCode"

const simpeInitDetailValues: SimpeDetails = {
  accountName: "",
  phoneNumber: ""
}
const cashDetailsInitValues: CashDetails = {

}
const bankTransferDetailsInitValues: BankTransferDetails = {
  accountNumber: "",
  bankName: "",
  accountName: "",
  swiftCode: ""
}
const paypalDetailsInitValues: PaypalDetails = {
  email: ""
}
const simpeFormInitValue: IPaymentMethod = {
  owner: "",
  name: "",
  type: PaymentMethodType.SINPE,
  details: simpeInitDetailValues,
  isActive: false,
  _id: ""
}
const cashFormInitValue: IPaymentMethod = {
  owner: "",
  name: "",
  type: PaymentMethodType.CASH,
  details: cashDetailsInitValues,
  isActive: false,
  _id: ""
}
const bankTransferFormInitValue: IPaymentMethod = {
  owner: "",
  name: "",
  type: PaymentMethodType.BANK_TRANSFER,
  details: bankTransferDetailsInitValues,
  isActive: false,
  _id: ""
}
const paypalFormInitValue: IPaymentMethod = {
  owner: "",
  name: "",
  type: PaymentMethodType.PAYPAL,
  details: paypalDetailsInitValues,
  isActive: false,
  _id: ""
}
interface IFormInitValue {
  targetType: PaymentMethodType,
  forms: Array<IPaymentMethod>
}
const formInitValue: IFormInitValue = {
  targetType: PaymentMethodType.SINPE,
  forms: [
    simpeFormInitValue,
    cashFormInitValue,
    bankTransferFormInitValue,
    paypalFormInitValue,
  ]
}

// PaypalDetails
// CashDetails
// BankTransferDetails
// SimpeDetails


const PaymentMethods = () => {
  const { user } = useAppSelector(state => state.Profile)
  const handleCurrencies = useHandleApiCall(GetCurrencies, {
    recallArguments: [],
    onSuccess: (response) => {
      /* handlePaymentValidation.setValues(prev => {
        return prev.forms.map(el => {
          if (el.type === prev.targetType) {
            return {
              ...el,

              currencyId: response.data[0]._id
            }
          }
        })
      }) */
    }
  });

  const handleGetPaymentMethods = useHandleApiCall(GetOwnerPaymentMethods, {
    recallArguments: [{
      ownerId: user?._id ?? ""
    }],
    ignoreError: true
  })

  const handleCreatePaymentMethod = useHandleApiCall(CreatePaymentMethod)
  const handleUpdatePaymentMethod = useHandleApiCall(UpdatePaymentMethod)
  const reload = useCallback(() => {
    handleGetPaymentMethods.refetch({
      ownerId: user?._id ?? ""
    })
  }, [handleGetPaymentMethods, user?._id])

  // Custom Tabs Bordered
  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const handlePaymentValidation = useFormik<{ targetType: any, forms: Array<IPaymentMethod> }>({
    enableReinitialize: true,
    initialValues: formInitValue,
    onSubmit: tog_center,
    /* validationSchema: Yup.object<ICreatePaymentMethod[]>({
      owner: Yup.string().required("Este campo es requerido"),
      name: Yup.string().required("Este campo es requerido"),
      type: Yup.string().required("Este campo es requerido"),
      isActive: Yup.boolean().required("Este campo es requerido"),
      currencyId: Yup.string().required("Este campo es requerido"),
    }), */
  })

  document.title = "Métodos de pago | Partarentals";

  const handleOnChangeForm = useCallback((key: DetailsKeys, value: any) => {
    //@ts-ignore
    handlePaymentValidation.setValues(prev => {
      const selectedType = prev.targetType
      return {
        ...prev,
        forms: prev.forms.map(el => {
          if (el.type === selectedType) {
            return {
              ...el,
              details: {
                ...el.details,
                [key]: value
              }
            }
          }
          return el
        })
      }
    })
  }, [handlePaymentValidation])

  const handleGetValueForm = useCallback((key: DetailsKeys) => {
    const selectedType = handlePaymentValidation.values.targetType
    const selectedForm = handlePaymentValidation.values.forms.find(el => el.type === selectedType)
    if (!selectedForm) return ""
    // @ts-ignore
    return selectedForm.details[key] ?? ""
  }, [handlePaymentValidation])

  const handleEnableDisablePaymentMethod = useCallback(() => {
    let type = handlePaymentValidation.values.targetType
    handlePaymentValidation.setValues(prev => {
      return {
        ...prev,
        forms: prev.forms.map(el => {
          if (el.type === type) {
            return {
              ...el,
              isActive: !el.isActive
            }
          }
          return el
        })
      }
    })
  }, [handlePaymentValidation])

  const handleGetPaymentAvailable = useCallback(() => {
    const selectedType = handlePaymentValidation.values.targetType
    const selectedForm = handlePaymentValidation.values.forms.find(el => el.type === selectedType)
    if (!selectedForm) return false
    return selectedForm.isActive
  }, [handlePaymentValidation])

  const handleCreateOrUpdatePaymentMethod = useCallback(async () => {
    try {

      for (let i = 0; i < handlePaymentValidation.values.forms.length; i++) {
        const form = handlePaymentValidation.values.forms[i]
        try {
          const details = form.details
          const userCashPaymentMethod = handleGetPaymentMethods.data?.find(paymentMethod => paymentMethod.type === PaymentMethodType.CASH)
          if (form.type === PaymentMethodType.CASH) {
            if (!form.isActive === !userCashPaymentMethod) {
              continue
            }
          }
          if (Object.keys(details).length > 0) {
            const values = Object.entries(details)
            if (values.some(value => value[1] === "")) {
              continue
            }
          }

          form.owner = user?._id ?? ""
          if (form._id) {
            await handleUpdatePaymentMethod.refetch(form._id, form)
          } else {
            // @ts-ignore
            await handleCreatePaymentMethod.refetch(form)
          }
          /* toast.success(`Método de pago ${form.type.toUpperCase()} configurado correctamente`) */
        } catch (error) {
          /* toast.error(`Ocurrio un error al configurar el Método de pago ${form.type.toUpperCase()}`) */
          console.log(error)
        }
      }
      toast.success(`Los métodos de pago han sido actualizados correctamente`)
      tog_center()
      reload()
    } catch (error) {
      toast.error("Ups! ocurrió un error en algunos métodos de pago")
      console.log(error)
    }
  }, [handleCreatePaymentMethod, handlePaymentValidation.values.forms, handleUpdatePaymentMethod, reload, user?._id, handleGetPaymentMethods.data])

  useEffect(() => {
    handlePaymentValidation.setValues(prev => {
      const forms = prev.forms.map(el => {
        const paymentMethod = handleGetPaymentMethods.data?.find(paymentMethod => paymentMethod.type === el.type)
        if (!paymentMethod) return el
        return {
          ...el,
          ...paymentMethod
        }
      })
      return {
        ...prev,
        forms
      }
    })
  }, [handleGetPaymentMethods.data])



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Métodos de pago" pageTitle="Pages" />
          <Row>
            <Col xs={12}>
              {/* <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Pago Seguro de PartaRentals</h4>
                </CardHeader>

                <CardBody>
                  <p className="text-muted">PartaRentals funciona como intermediario para procesar los pagos de alquiler de manera segura. El dinero es recibido por PartaRentals y luego depositado directamente en la cuenta bancaria que configures a continuación. Este método proporciona una mayor confianza y seguridad tanto para arrendadores como arrendatarios.</p>
                  <div className="live-preview">
                    <form action="#" onSubmit={handlePaymentValidation.handleSubmit}>
                      <div>
                        <h5 className="fs-14 mb-3">Configura donde recibirás los pagos</h5>
                        <Row >
                          <Col xl={4}>
                            <div className="mb-3">
                              <Label htmlFor="ibanAccount" className="form-label">Cuenta IBAN</Label>
                              <Input type="text" className="form-control" id="ibanAccount" />
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div className="mb-3">
                              <Label htmlFor="basiInput" className="form-label">Nombre de la Institución Bancaria</Label>
                              <select className="form-select mb-3" aria-label="Default select example">
                                <option >Selecciona una opción</option>
                                <option value="1">Declined Payment</option>
                                <option value="2">Delivery Error</option>
                                <option value="3">Wrong Amount</option>
                              </select>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div className="mb-3">
                              <Label htmlFor="fullname" className="form-label">Nombre del Titular de la Cuenta</Label>
                              <Input type="text" className="form-control" id="fullname" />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={4}>
                            <div className="mb-3">
                              <Label htmlFor="documentid" className="form-label">Cédula del Titular de la Cuenta</Label>
                              <Input type="text" className="form-control" id="documentid" />
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div className="mb-3">
                              <Label htmlFor="currency" className="form-label">Moneda de la Cuenta</Label>
                              <Select
                                placeholder="Seleccione una moneda"
                                value={(() => {
                                  const selectedCurrency = handleCurrencies.data?.find(currency => currency._id === handlePaymentValidation.values.currencyId)
                                  if (!selectedCurrency) return { value: "", label: "Selecciona una moneda" }
                                  return { value: selectedCurrency?._id, label: selectedCurrency?.nombre }
                                })()}
                                onChange={(selectedOption: any) => {
                                  if (!selectedOption) return
                                }}
                                options={handleCurrencies.data?.map((currency) => ({ value: currency._id, label: currency.nombre })) || []}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="text-end">
                              <button type="button" className="btn btn-success w-100-mobile" onClick={() => tog_center()}>Guardar métodos de pago</button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card> */}

              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Método de Pago a Convenir</h4>
                </CardHeader>

                <CardBody>
                  <p className="text-muted">PartaRentals no se hace responsable por actos de mala fé provenientes del uso de estos métodos de pago</p>
                  <div className="live-preview">
                    <Row>
                      <Col md={12}>
                        <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: handlePaymentValidation.values.targetType === PaymentMethodType.SINPE,
                              })}
                              onClick={() => {
                                handlePaymentValidation.setValues(prev => ({
                                  ...prev,
                                  targetType: PaymentMethodType.SINPE
                                }))
                              }}
                            >
                              SINPE
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: handlePaymentValidation.values.targetType === PaymentMethodType.CASH,
                              })}
                              onClick={() => {
                                handlePaymentValidation.setValues(prev => ({
                                  ...prev,
                                  targetType: PaymentMethodType.CASH,
                                  details: {

                                  }
                                }))
                              }}
                            >
                              Efectivo
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: handlePaymentValidation.values.targetType === PaymentMethodType.PAYPAL,
                              })}
                              onClick={() => {
                                handlePaymentValidation.setValues(prev => ({
                                  ...prev,
                                  targetType: PaymentMethodType.PAYPAL
                                }))
                              }}
                            >
                              Pago Electrónico
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: handlePaymentValidation.values.targetType === PaymentMethodType.BANK_TRANSFER,
                              })}
                              onClick={() => {

                                handlePaymentValidation.setValues(prev => ({
                                  ...prev,
                                  targetType: PaymentMethodType.BANK_TRANSFER
                                }))
                              }}
                            >
                              Transferencia Internacional
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                    <form action="#">
                      <TabContent activeTab={handlePaymentValidation.values.targetType} className="text-muted">

                        <TabPane tabId={PaymentMethodType.SINPE} id="home1">
                          <div>
                            <h5 className="fs-14 mb-3">Transferencia SINPE</h5>
                            <p>La mayoría de las entidades bancarias establecen un límite diario de SINPE móviil. Favor asegurarse que su límite cubre el total del canon.</p>
                            <Row >
                              <Col xl={4}>
                                <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                    defaultChecked
                                    checked={handleGetPaymentAvailable()}
                                    onChange={(e) => handleEnableDisablePaymentMethod()}
                                  />
                                  <Label className="form-check-label" htmlFor="customSwitchsizelg">Aceptar pagos con SINPE</Label>
                                </div>
                              </Col>
                            </Row>
                            <Row >
                              <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="phonenumber" className="form-label">Número de teléfono</Label>
                                  <Input type="number" className="form-control" id="phonenumber"
                                    value={handleGetValueForm('phoneNumber')}
                                    onChange={(e) => handleOnChangeForm('phoneNumber', e.target.value)}
                                    disabled={!handlePaymentValidation.values.forms.find(el => el.type === PaymentMethodType.SINPE)?.isActive}
                                  />
                                </div>
                              </Col>

                              <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="fullname" className="form-label">Nombre del titular de la cuenta</Label>
                                  <Input
                                    type="text" className="form-control" id="fullname"
                                    value={handleGetValueForm('accountName')}
                                    onChange={(e) => handleOnChangeForm('accountName', e.target.value)}
                                    disabled={!handlePaymentValidation.values.forms.find(el => el.type === PaymentMethodType.SINPE)?.isActive}
                                  />
                                </div>
                              </Col>
                            </Row>

                          </div>

                        </TabPane>

                        <TabPane tabId={PaymentMethodType.CASH}>

                          <div>
                            <h5 className="fs-14 mb-3">Pago en efectivo</h5>
                            <p>Los arrendatarios te pagarán con efectivos sus alquileres mes a mes.</p>
                            <Row >
                              <Col xl={4}>
                                <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                    defaultChecked
                                    checked={handleGetPaymentAvailable()}
                                    onChange={(e) => handleEnableDisablePaymentMethod()}
                                  />
                                  <Label className="form-check-label" htmlFor="customSwitchsizelg">Aceptar pagos en efectivo</Label>
                                </div>
                              </Col>
                            </Row>

                          </div>

                        </TabPane>

                        <TabPane tabId={PaymentMethodType.PAYPAL}>

                          <div>
                            <h5 className="fs-14 mb-3">Paypal</h5>
                            <p>Recuerda que Paypal puede cobrar una comisión de 2,90 % + 0,30 USD por transacción. </p>

                            <Row >
                              <Col xl={4}>
                                <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                    defaultChecked
                                    checked={handleGetPaymentAvailable()}
                                    onChange={(e) => handleEnableDisablePaymentMethod()}
                                  />
                                  <Label className="form-check-label" htmlFor="customSwitchsizelg">Aceptar pagos electrónicos</Label>
                                </div>
                              </Col>
                            </Row>
                            <Row >
                              {/* <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="fullname" className="form-label">Nombre del titular</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="fullname"
                                    value={handleGetValueForm('accountName')}
                                    onChange={(e) => handleOnChangeForm('accountName', e.target.value)}
                                  />
                                </div>
                              </Col> */}

                              <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="email" className="form-label">Correo electrónico del titular</Label>
                                  <Input
                                    type="email" className="form-control" id="email"
                                    value={handleGetValueForm('email')}
                                    onChange={(e) => handleOnChangeForm('email', e.target.value)}
                                    disabled={!handlePaymentValidation.values.forms.find(el => el.type === PaymentMethodType.PAYPAL)?.isActive}
                                  />
                                </div>
                              </Col>

                              {/* <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="swift" className="form-label">Usuario</Label>
                                  <Input
                                    type="text" className="form-control" id="username"
                                    value={handleGetValueForm('accountName')}
                                    onChange={(e) => handleOnChangeForm('accountName', e.target.value)}
                                  />
                                </div>
                              </Col> */}
                            </Row>

                          </div>

                        </TabPane>

                        <TabPane tabId={PaymentMethodType.BANK_TRANSFER}>

                          <div>
                            <h5 className="fs-14 mb-3">Transferencia internacional</h5>
                            <p>Las comisiones asociadas al envío son cobradas al cliente emisor de la transferencia.</p>
                            <Row >
                              <Col xl={4}>
                                <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                    defaultChecked
                                    checked={handleGetPaymentAvailable()}
                                    onChange={(e) => handleEnableDisablePaymentMethod()}
                                  />
                                  <Label className="form-check-label" htmlFor="customSwitchsizelg">Aceptar transferencia internacional</Label>
                                </div>
                              </Col>
                            </Row>

                            <Row >
                              <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="fullname" className="form-label">Nombre del titular</Label>
                                  <Input type="text" className="form-control" id="fullname"
                                    disabled={!handlePaymentValidation.values.forms.find(el => el.type === PaymentMethodType.BANK_TRANSFER)?.isActive}
                                    value={handleGetValueForm('accountName')}
                                    onChange={(e) => handleOnChangeForm('accountName', e.target.value)}
                                  />
                                </div>
                              </Col>

                              <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="bankaccount" className="form-label">Número de cuenta bancaria</Label>
                                  <Input type="text" className="form-control" id="bankaccount"
                                    disabled={!handlePaymentValidation.values.forms.find(el => el.type === PaymentMethodType.BANK_TRANSFER)?.isActive}
                                    value={handleGetValueForm('accountNumber')}
                                    onChange={(e) => handleOnChangeForm('accountNumber', e.target.value)}
                                  />
                                </div>
                              </Col>

                              <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="swift" className="form-label">Código SWIFT/BIC</Label>
                                  <Input type="text" className="form-control" id="swift"
                                    disabled={!handlePaymentValidation.values.forms.find(el => el.type === PaymentMethodType.BANK_TRANSFER)?.isActive}
                                    value={handleGetValueForm('swiftCode')}
                                    onChange={(e) => handleOnChangeForm('swiftCode', e.target.value)}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row >
                              <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="bankname" className="form-label">Nombre del banco</Label>
                                  <Input type="text" className="form-control" id="bankname"
                                    disabled={!handlePaymentValidation.values.forms.find(el => el.type === PaymentMethodType.BANK_TRANSFER)?.isActive}
                                    value={handleGetValueForm('bankName')}
                                    onChange={(e) => handleOnChangeForm('bankName', e.target.value)}
                                  />
                                </div>
                              </Col>

                              {/* <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="bankaddress" className="form-label">Dirección del banco</Label>
                                  <Input type="text" className="form-control" id="bankaddress" />
                                </div>
                              </Col> */}

                              {/* <Col xl={4}>
                                <div className="mb-3">
                                  <Label htmlFor="country" className="form-label">País</Label>
                                  <Input type="text" className="form-control" id="country" />
                                </div>
                              </Col> */}
                            </Row>

                          </div>

                        </TabPane>

                        <Row>
                          <Col md={12}>
                            <div className="text-end">
                              <button type="button" className="btn btn-success w-100-mobile" onClick={() => tog_center()}>Guardar métodos de pago</button>
                            </div>
                          </Col>
                        </Row>
                      </TabContent>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >

      {/* Vertically Centered */}
      <Modal Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-5">
          <i className="bx bx-money-withdraw text-warning" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estás seguro de configurar estos métodos de pago?</h4>
            <p className="text-muted mb-4">Sus arrendatarios podrán pagar sus cánones a través de estos métodos de pago seleccionados</p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
              <Button color="success" onClick={() => handleCreateOrUpdatePaymentMethod()}>Configurar</Button>
            </div>
          </div>
        </ModalBody>
      </Modal >

    </React.Fragment >
  );
};

export default PaymentMethods;