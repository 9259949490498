import { IContract } from "./contracts"
import { PaymentMethodType } from "./paymentMethod"

export { PaymentMethodType }

export enum PaymentStatus {
    PENDING = "pending",
    COMPLETED = "completed",
    REJECTED = "rejected",
}

export enum RejectionState {
    NO_PAYMENT_RECEIVED = 'no_payment_received',
    INCOMPLETE_PAYMENT = 'imcomplete_payment',
    OTHER = 'other',
}

export const RejectionStateMap = {
    [RejectionState.NO_PAYMENT_RECEIVED]: "No se recibió el pago",
    [RejectionState.INCOMPLETE_PAYMENT]: "Pago incompleto",
    [RejectionState.OTHER]: "Otro",
}

export const PaymentStatusMap = {
    [PaymentStatus.PENDING]: "PAGO POR APROBAR",
    [PaymentStatus.COMPLETED]: "PAGO APROBADO",
    [PaymentStatus.REJECTED]: "PAGO RECHAZADO",
}

export interface ICreatePayment {
    from: string,
    to: string,
    contract: string,
    paymentMethod: PaymentMethodType,
    amount: number,
    status: PaymentStatus,
    description: string,
    proof: File
    paymentDate: Date
}


export interface IUpdatePaymentStatus {
    status: PaymentStatus
    rejectionReason?: string
    rejectionState?: RejectionState
}

export interface IPayment {
    _id: string
    from: string
    to: string
    contract: IContract
    paymentMethod: PaymentMethodType
    amount: number
    status: PaymentStatus
    description: string
    proof: string
    paymentDate: Date
    rejectionReason?: string
    rejectionState?: RejectionState
    accountStatements?: string[]
}

