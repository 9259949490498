import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';

import AvatarIcon from '../../../../../assets/images/users/avatar-3.jpg'
import Select from "react-select";
import { C } from '@fullcalendar/core/internal-common';
import { EndContractApproved, GetContracts, UpdateContract } from 'service/contracts'
import { ContractStatus, IContract } from 'types/api/contracts';
import TableContainer from 'Components/Common/TablePartaRentals';
import TemplatePreviewContracts from '../Template';

import { CreateTenantRating } from "service/rating"
import { MarkContractNonRenewable } from "service/contracts"
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';
import { useHandleApiCall } from 'hooks/useHandleApiCall';


const cantidadOptions: { label: string; value: string }[] = new Array(10).fill(null).map((el, i) => ({ label: `${i}`, value: `${i}` }))

const ThreeMothMilliSeconds = 1000 * 60 * 60 * 24 * 30 * 3
const TwelveMothMilliSeconds = 1000 * 60 * 60 * 24 * 30 * 12
const SixMothMilliSeconds = 1000 * 60 * 60 * 24 * 30 * 6
const ActiveContracts = () => {
  const { user } = useAppSelector(state => state.Profile)
  const navigate = useNavigate()
  const [modal_center, setmodal_center] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IContract | null>(null);
  const handleMarkNonRenewableCall = useHandleApiCall(MarkContractNonRenewable)

  function togRatingModal() {
    setmodal_center(!modal_center);
  }
  function tog_center() {
    setmodal_center(!modal_center);
  }
  const [modal_declined, setmodal_declined] = useState<boolean>(false);
  function decline_payment() {
    setmodal_declined(!modal_declined);
  }

  const ratingValidation = useFormik({
    initialValues: {
      comment: "",
      rating: 0
    },
    onSubmit: (values) => {
      handleRating(values.comment, values.rating)
    }
  })

  const handleRating = async (comment: string, rating: number) => {
    try {
      if (!selectedContract) return
      await CreateTenantRating({
        owner: selectedContract.owner._id,
        tenant: selectedContract.tenant._id,
        ratingType: "owner_to_tenant",
        comment,
        rating
      })
      togRatingModal()
      toast.success("Valoración realizada con éxito")
    } catch (error) {
      console.log(error)
    }
  }

  const handleFinishContract = useCallback(async (contract: IContract, refetch: any) => {
    await UpdateContract(contract._id, { status: ContractStatus.PENDING_COMPLETION })
    refetch()
    toast.success("Contrato finalizado")
  }, [])

  const handleCompleteContract = useCallback(async (contract: IContract) => {
    await UpdateContract(contract._id, { status: ContractStatus.COMPLETED })
    toast.success("Contrato completado")
  }, [])

  const handleMarkAsNonRenewable = useCallback(async (contract: IContract, cb: ()=>{}) => {
    try {
     await handleMarkNonRenewableCall.refetch(contract._id, {
        reason: "No renovable"
      })
      cb()
      toast.success("Contrato marcado como no renovable")
    } catch (error) {
      toast.error("Error al marcar contrato como no renovable")
    }}, [])

  return <>
    <TemplatePreviewContracts
      headerTitle='Contratos activos'
      statusFilter={[
        ContractStatus.ACTIVE,
        ContractStatus.PENDING_COMPLETION,
      ]}
      contractType="contracts"
      itemsActions={[

        {
          label: "Notificar no renovar",
          action: (v, reload) => {
            handleMarkAsNonRenewable(v, reload)
          },
          // @ts-ignore
          validation: (v) => {
            if (v.status === ContractStatus.PENDING_COMPLETION ||
              v.status === ContractStatus.REJECTED ||
              v.status === ContractStatus.PENDING_APPROVAL) {
              return false
            }
            if (v.tenant._id !== user?._id) {
              return false
            }
            return ((new Date(v.endDate).getTime() - Date.now()) > ThreeMothMilliSeconds) && v.status === ContractStatus.ACTIVE && (v.owner?._id === user?._id || v.agent?._id === user?._id)
          }
        },
        {
          label: "Renovar",
          action: (v) => {
            navigate(`/contracts-renew/${v._id}`)
          },
          // @ts-ignore
          validation: (v) => {
            if (v.status === ContractStatus.PENDING_COMPLETION ||
              v.status === ContractStatus.REJECTED ||
              v.status === ContractStatus.PENDING_APPROVAL) {
              return false
            }
            if (v.owner._id !== user?._id && v.agent._id !== user?._id) {
              return false
            }
            /* 
            return ((new Date(v.endDate).getTime() - Date.now()) > SixMothMilliSeconds) && v.status === ContractStatus.ACTIVE && (v.owner?._id === user?._id || v.agent?._id === user?._id) &&
            user?._id === v.owner._id || user?._id === v.agent._id
            */
            return user?._id === v.owner._id || user?._id === v.agent._id
          }
        },
        {
          label: "Finalizar contrato",
          action: handleFinishContract,
          // @ts-ignore
          validation: (v) => v.status === ContractStatus.ACTIVE && v.tenant._id === user?._id && [Role.Tenant, Role.Admin].includes(user?.roles[0])
        },
        { label: "Ver detalle", action: (v) => { navigate(`/contracts-details/${v._id}`) } },
        {
          label: "Editar",
          action: (v) => { navigate(`/contracts-register/${v._id}`) },
          validation: (v) => (new Date(v.startDate).getTime() - Date.now()) > TwelveMothMilliSeconds && v.status === ContractStatus.ACTIVE // TODO Fix this validation last renew date
        },
        {
          label: "Valorar",
          action: (v) => {
            setSelectedContract(v)
            tog_center()
          },
          validation: (v) => (new Date(v.startDate).getTime() - Date.now()) > TwelveMothMilliSeconds && v.status === ContractStatus.ACTIVE// TODO Fix this validation las rating date
        },
      ]} />
    {/* Vertically Centered */}
    <Modal
      isOpen={modal_center}
      toggle={() => {
        tog_center();
      }}
      centered
    >
      <ModalHeader className="modal-title" />

      <ModalBody className="text-center p-4">
        <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
        <div className="mt-4">
          <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
          <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
          <div className="hstack gap-2 justify-content-center">
            <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
            <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>


    {/* Vertically Centered */}
    <Modal
      isOpen={modal_center}
      toggle={() => {
        togRatingModal();
      }}
      centered
    >
      <ModalHeader className="modal-title" />

      <ModalBody className="text-center p-4">
        <i className="bx bxs-star text-primary" style={{ fontSize: "60px" }}></i>
        <Form onSubmit={(e) => {
          e.preventDefault()
          ratingValidation.handleSubmit(e)
        }}>
          <div className="mt-4">
            <h4 className="mb-2 fw-bold">Valora al
              {user?._id === selectedContract?.tenant._id && "Inquilino"}
              {user?._id === selectedContract?.agent._id && "Agente"}
              {user?._id === selectedContract?.owner._id && "Propietario"}
            </h4>
            <p className="text-muted mb-3">Esta valoración será publica.</p>
            <div>
              <h5 className='d-flex justify-content-center fw-bold text-align-center'>Califique al
                {user?._id === selectedContract?.tenant._id && "Inquilino"}
                {user?._id === selectedContract?.agent._id && "Agente"}
                {user?._id === selectedContract?.owner._id && "Propietario"}
                con estrellas</h5>
              <Rating
                initialValue={ratingValidation.values.rating}
                size={22}
                allowHover={false}
                allowFraction={true}
                onClick={(value) => {
                  ratingValidation.setValues({ ...ratingValidation.values, rating: value })
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '1rem'
                }}
              />{/*Aquí va la barra de rating, no supe colocarla*/}
            </div>
            <div>
              <h5 className='d-flex justify-content-start fw-bold'>Comentario</h5>
              <textarea
                className='form-control mb-3'
                id="comment"
                name="comment"
                value={ratingValidation.values.comment}
                onChange={ratingValidation.handleChange}
              ></textarea>
            </div>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
              <Button color="success" type='submit'>Valorar</Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>

    {/* Vertically Centered */}
    <Modal
      isOpen={modal_declined}
      toggle={() => {
        decline_payment();
      }}
      centered
    >
      <ModalHeader className="modal-title" />

      <ModalBody className="text-center p-4">
        <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
        <div className="mt-4">
          <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
          <form action="">
            <div className="mb-3 text-start">
              <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
              <select className="form-select mb-3" aria-label="Default select example">
                <option value="pago_incompleto">Pago incompleto</option>
              </select>
            </div>
            <div className="mb-3 text-start">
              <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
              <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea>
            </div>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => setmodal_declined(false)}>Cancelar</Button>
              <Button color="danger" onClick={() => console.log("Funciona")}>Rechazar</Button>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  </>
};

export default ActiveContracts;