import React from 'react';
import { ToastContainer } from 'react-toastify';


export interface WithToastContainerProps {
    children: any;
    key?: string;
}

export const WithToastContainer = (props: WithToastContainerProps) => {
    return (
        <React.Fragment>
            {props.children}
            <ToastContainer containerId={props.key ?? ""} />
        </React.Fragment>
    );
};