import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';

import AvatarIcon from '../../../../../assets/images/users/avatar-3.jpg'
import Select from "react-select";
import { C } from '@fullcalendar/core/internal-common';
import { ContractStatus } from 'types/api/contracts';
import TemplatePreviewContracts from '../Template';
import { DeleteContract, UpdateContract } from "service/contracts"
import { toast } from 'react-toastify';
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';

const cantidadOptions: { label: string; value: string }[] = new Array(10).fill(null).map((el, i) => ({ label: `${i}`, value: `${i}` }))
const ThreeMothMilliSeconds = 1000 * 60 * 60 * 24 * 30 * 3

const ContractOffers = () => {
  document.title = "Ofertas de contrato | Partarentals";
  const navigate = useNavigate()
  const [modal_center, setmodal_center] = useState<boolean>(false);
  const [modal_declined, setmodal_declined] = useState<boolean>(false);
  const [modal_end_offer, setmodal_end_offer] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<any | null>(null);
  const penalty = useMemo<boolean>(() => {
    if (!selectedContract) return false
    const monthPenaltyPeriod = selectedContract.penaltyPeriod
    const penaltyPeriodMilliseconds = monthPenaltyPeriod * 1000 * 60 * 60 * 24 * 30
    const initContract = new Date(selectedContract.startDate).getTime()
    const now = Date.now()
    const deltaTime = now - initContract
    if (deltaTime < penaltyPeriodMilliseconds) {
      return true
    } else {
      return false
    }
  }, [selectedContract]);

  function tog_accept_payment() {
    setmodal_center(!modal_center);
  }
  function tog_decline_payment() {
    setmodal_declined(!modal_declined);
  }

  function tog_end_offer_payment() {
    setmodal_end_offer(!modal_end_offer);
  }


  const { user } = useAppSelector(state => state.Profile)

  const handleEndOffer = useCallback(async () => {
    try {
      if (!selectedContract) return
      const monthPenaltyPeriod = selectedContract.penaltyPeriod
      const penaltyPeriodMilliseconds = monthPenaltyPeriod * 1000 * 60 * 60 * 24 * 30

      const initContract = new Date(selectedContract.startDate).getTime()
      const now = Date.now()
      const deltaTime = now - initContract

      if (deltaTime < penaltyPeriodMilliseconds) {
        console.log("No se puede finalizar el contrato")
      }

      await UpdateContract(selectedContract._id, { ...selectedContract, status: ContractStatus.COMPLETED })
      navigate("/contracts-offers-preview")
      toast.success("Contrato finalizado")
    } catch (error) {
      toast.error("Error al finalizar el contrato")
    }
  }, [navigate, selectedContract])

  return (
    <React.Fragment>
      <TemplatePreviewContracts
        headerTitle='Ofertas de contrato'
        statusFilter={[
          ContractStatus.PENDING_APPROVAL,
          ContractStatus.PENDING_PAYMENT_APPROVAL,
          ContractStatus.REJECTED,
          ContractStatus.REJECTED_PAYMENT,
        ]}
        contractType="offers"
        itemsActions={
          [
            {
              label: "Ver detalle",
              action: (v) => {
                navigate(`/contracts-details/${v._id}`);
              },
            },
            {
              label: "Retirar oferta",
              action: async (v, reloadPage) => {
                await DeleteContract(v._id);
                reloadPage();
                toast.success("Oferta retirada");
              },
              validation: (v) =>
                v.status === ContractStatus.PENDING_APPROVAL &&
                // @ts-ignore
                [Role.Admin, Role.Agent, Role.Owner].includes(user?.roles[0] || '') && (v.owner._id === user?._id || v.agent?._id === user?._id),
            },
          ]} />

      {/* Vertically Centered */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_accept_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
            <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
              <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Vertically Centered */}
      <Modal
        isOpen={modal_declined}
        toggle={() => {
          tog_decline_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
            <form action="">
              <div className="mb-3 text-start">
                <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
                <select className="form-select mb-3" aria-label="Default select example">
                  <option value="pago_incompleto">Pago incompleto</option>
                </select>
              </div>
              <div className="mb-3 text-start">
                <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea>
              </div>
              <div className="hstack gap-2 justify-content-center">
                <Button color="light" onClick={() => setmodal_declined(false)}>Cancelar</Button>
                <Button color="danger" onClick={() => console.log("Funciona")}>Rechazar</Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal_end_offer}
        toggle={() => {
          tog_end_offer_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="bx ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de finalizar el contrato?</h4>
            {penalty && <p className="text-muted mb-4">Se aplicará la penalidad por finalización de contrato establecida al momento de constituir el mismo.</p>}
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => setmodal_end_offer(false)}>Cancelar</Button>
              <Button color="success" onClick={handleEndOffer}>Aprobar</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
};

export default ContractOffers;