import { IContract } from "./contracts"

export enum ClaimStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  RESOLVED = 'resolved',
}

export interface IClaim  {
  _id: string
  contract: IContract
  description: string
  amount: number
  creationDate: Date    
  status: `${ClaimStatus}`
  claimantId: string
  resolvedBy: string
  resolutionDate: Date
  resolutionNotes: string
  invoiceProof: any 
  evidences: any[]
  createdAt: Date;
  updatedAt: Date;
}

export interface ICreateClaim {
  contract: string
  description: string
  amount: number
  invoiceProof?: File
  file?: File[]
  evidences?: File[]
}

export interface IUpdateClaim {
  contract?: string
  description?: string
  amount?: number
  invoiceProof?: File
  file?: File[]
  evidences?: File[]
  status?: ClaimStatus
  resolvedBy?: string
}