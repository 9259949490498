export const termsAndConditions = `Última actualización: 31/10/2024

Bienvenido a PartaRentals. Estos Términos y Condiciones (“Términos”) regulan el uso de nuestros servicios de alquiler, así como los derechos y responsabilidades entre PartaRentals y sus clientes. Al utilizar nuestros servicios, el cliente acepta cumplir con estos Términos. Para cualquier consulta, comuníquese con nosotros a admin@partarentals.com.

1. Introducción

PartaRentals es una empresa dedicada al alquiler de equipos y vehículos en Costa Rica. Nuestro compromiso es ofrecer una experiencia de alquiler confiable, segura y personalizada para nuestros clientes. Al alquilar con nosotros, usted se compromete a cumplir con los Términos establecidos en este documento.

2. Definiciones

Para efectos de este documento:

	•	Cliente se refiere a cualquier persona que alquile bienes o servicios de PartaRentals.
	•	Equipo hace referencia a los bienes ofrecidos en alquiler, como vehículos, herramientas y otros activos de la empresa.
	•	Contrato de Alquiler significa el acuerdo que se establece entre PartaRentals y el Cliente, a partir del cual el Cliente tiene derecho de uso de los equipos, conforme a los términos aquí establecidos.

3. Requisitos del Cliente

Para realizar un alquiler con PartaRentals, el Cliente debe cumplir con los siguientes requisitos:

	•	Edad mínima: El Cliente debe tener al menos 18 años.
	•	Identificación válida: El Cliente debe presentar una identificación emitida por el gobierno (cédula, pasaporte).
	•	Licencia de conducir (para vehículos): En caso de alquilar un vehículo, el Cliente debe presentar una licencia de conducir válida y en vigencia.
	•	Garantía de pago: PartaRentals requiere una tarjeta de crédito o depósito en garantía para cubrir posibles daños o incidentes.

4. Reservas y Pagos

	1.	Reserva: Las reservas pueden realizarse en línea, por teléfono o en persona. El Cliente recibirá una confirmación de la reserva, que incluye los detalles del equipo, la tarifa de alquiler y la duración.
	2.	Pago: El Cliente deberá realizar el pago completo del alquiler antes de recibir el equipo. PartaRentals acepta pagos en efectivo, transferencia bancaria o tarjeta de crédito.
	3.	Cancelación: Para cancelar una reserva, el Cliente debe comunicarse con PartaRentals al menos 24 horas antes de la fecha de alquiler. Las cancelaciones realizadas con menos de 24 horas de antelación pueden estar sujetas a un cargo.

5. Duración del Alquiler y Extensiones

	1.	Periodo de alquiler: La duración del alquiler se especificará en el Contrato de Alquiler y comenzará desde la entrega del equipo al Cliente.
	2.	Extensión de alquiler: Para extender el periodo de alquiler, el Cliente deberá notificar a PartaRentals con antelación. La extensión estará sujeta a disponibilidad y se cobrará de acuerdo con las tarifas vigentes.
	3.	Devolución tardía: Si el equipo no se devuelve en la fecha acordada y sin previa autorización, PartaRentals aplicará un cargo adicional por día de retraso.

6. Obligaciones del Cliente

	1.	Uso adecuado del equipo: El Cliente se compromete a utilizar el equipo de manera responsable y exclusivamente para los fines permitidos.
	2.	Responsabilidad: El Cliente es responsable de cualquier daño, pérdida o robo del equipo durante el periodo de alquiler. En caso de daño, el Cliente asumirá los costos de reparación o reposición del equipo.
	3.	Mantenimiento del equipo: El Cliente debe mantener el equipo en buen estado y devolverlo en las mismas condiciones en que fue entregado, salvo el desgaste normal por uso.

7. Responsabilidad de PartaRentals

	1.	Calidad del equipo: PartaRentals garantiza que el equipo está en condiciones óptimas de uso y ha sido revisado antes de su entrega.
	2.	Asistencia: En caso de problemas con el equipo, PartaRentals brindará asistencia técnica. En caso de que el equipo no funcione correctamente, PartaRentals podrá reemplazarlo o reembolsar proporcionalmente el monto pagado.
	3.	Limitación de responsabilidad: PartaRentals no será responsable por daños indirectos, incidentales o consecuentes derivados del uso del equipo, salvo que la ley exija lo contrario.

8. Política de Seguro

PartaRentals ofrece opciones de seguro para cubrir daños accidentales y robos. El Cliente puede optar por adquirir esta cobertura adicional en el momento de realizar el alquiler. La cobertura y limitaciones de cada plan de seguro se especificarán en el Contrato de Alquiler.

9. Modificaciones a los Términos y Condiciones

PartaRentals se reserva el derecho de modificar estos Términos en cualquier momento. Las modificaciones serán notificadas a los Clientes y publicadas en nuestro sitio web. Es responsabilidad del Cliente revisar periódicamente los Términos para mantenerse informado de posibles cambios.

10. Legislación y Jurisdicción

Estos Términos se rigen por las leyes de Costa Rica. Cualquier disputa relacionada con el alquiler o el uso del equipo será resuelta en los tribunales competentes de Costa Rica.

Contacto

Si tiene alguna pregunta sobre estos Términos y Condiciones, por favor contacte a Gusmar Atencio, representante de PartaRentals, al correo electrónico admin@partarentals.com.

Aceptación de Términos

Al proceder con el alquiler, el Cliente reconoce que ha leído, entendido y aceptado estos Términos y Condiciones.`