import { cl } from "@fullcalendar/core/internal-common";
import { useHandleApiCall } from "hooks/useHandleApiCall";
import { useProfileUtils } from "hooks/useProfileUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { toast } from "react-toastify";
import { GetOwnerPaymentMethods } from "service/paymentMethods";
import { useAppSelector } from "slices/hooks";
import { Role } from "types";
enum MenuItems {
    Dashboard = 'Dashboard',
    FavoriteProperties = 'Propiedades favoritas',
    Users = 'Usuarios',
    Properties = 'Properties',
    Contracts = 'Contratos',
    Payments = 'Pagos',
    Agents = 'Agents',
}

const RoleEquivalent = {
    [Role.Tenant]: "Arrendatario",
    [Role.Owner]: "Arrendador",
    [Role.Agent]: "Arrendador",
    [Role.Admin]: "Admin",

}




const Navdata = () => {
    const history = useNavigate();
    const { user } = useAppSelector(state => state.Profile)
    const { isProfileComplete } = useProfileUtils()
    const { pathname } = useLocation()
    //Parta Rentals
    const [isUsers, setIsUsers] = useState<boolean>(false);

    const [isProperties, setIsProperties] = useState<boolean>(false);
    const [isContract, setIsContract] = useState<boolean>(false);
    const [isPayments, setIsPayments] = useState<boolean>(false);
    const [isFavoriteProperties, setIsFavoriteProperties] = useState<boolean>(false);



    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement;
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }



    const handleGetPaymentMethods = useHandleApiCall(GetOwnerPaymentMethods, {
        recallArguments: [{
            ownerId: user?._id ?? ""
        }],
        ignoreError: true
    })

    const checkProfileClickAction = useCallback(async (checkProfile: boolean, checkPayment: boolean, ignoreAgent: boolean, url: string, cb?: () => {}) => {

        if (!user?._id) return

        if (user.roles[0] === Role.Tenant) return history(url.trim())

        if (ignoreAgent && user.roles[0] === Role.Agent) {
            return
        }
        if (checkProfile)
            if (!isProfileComplete) {
                if (pathname === url) return
                setTimeout(() => {
                    history("/pages-profile-settings")
                }, 0)
                return toast.error("Completa tu perfil para poder registrar un contrato")
            }

        if (checkPayment) {
            const paymentMethodsData = await handleGetPaymentMethods.refetch({
                ownerId: user?._id ?? ""
            })
            if (paymentMethodsData?.length === 0) { 
                if (pathname === url) return
                setTimeout(() => {
                    history("/payment-methods")
                }, 0)
                return toast.error("Registra un método de pago para poder registrar un contrato")
            }
        }

        return history(url.trim())
    }, [handleGetPaymentMethods.data, history, isProfileComplete, pathname, user?._id, user?.roles])

    const checkProfileUrlAction = useCallback((checkProfile: boolean, checkPayment: boolean, ignoreAgent: boolean, url: string) => {
        let _url = url
        if (!user?._id) return

        if (user.roles[0] === Role.Tenant) return _url.trim()
        if (!ignoreAgent)
            if (user.roles[0] === Role.Agent) {
                _url = url
            }
        if (checkProfile)
            if (!isProfileComplete) {
                if (url === "/pages-profile-settings") return _url
                _url = "#"
            }

        if (checkPayment) {
            if (handleGetPaymentMethods.data?.length === 0) {
                if (url === "/payment-methods") return _url
                _url = "#"
            }
        }

        return _url.trim()
    }, [handleGetPaymentMethods.data?.length, isProfileComplete, user?._id, user?.roles])

    const menuItems: any = useMemo(() => {
        return [
            {
                label: `Menu ${user?.roles[0] ? RoleEquivalent[user?.roles[0]] : ""}`,
                isHeader: true,
            },
            user?.roles && [Role.Tenant].includes(user?.roles[0]) && {
                id: "favoriteproperties",
                label: "Propiedades favoritas",
                icon: "ri-dashboard-2-line",
                link: "/#",
                stateVariables: isFavoriteProperties,
                click: function (e: any) {
                    e.preventDefault();
                    setIsFavoriteProperties(prev => !prev);
                    updateIconSidebar(e);
                },
            },
            user?.roles && [Role.Admin].includes(user?.roles[0]) && {
                id: "users",
                label: "Usuarios",
                icon: " ri-hotel-line",
                link: "/pages-property-lists",
                stateVariables: isUsers,
                click: function (e: any) {
                    e.preventDefault();
                    setIsUsers(prev => !prev);
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "usersdetails",
                        label: "Visualizar un usuario",
                        link: "/pages-user-register",
                        parentId: "users",
                    },
                    {
                        id: "usersform",
                        label: "Crear un usuario",
                        link: "/pages-users-form",
                        parentId: "users",
                    },
                ]
            },
            user?.roles && [Role.Owner, Role.Agent, Role.Admin].includes(user?.roles[0]) && {
                id: "properties",
                label: "Propiedades",
                icon: " ri-hotel-line",
                link: "/pages-property-lists",
                stateVariables: isProperties,
                click: function (e: any) {
                    e.preventDefault();
                    setIsProperties(prev => !prev);
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "propertiesform",
                        label: "Subir una propiedad",
                        link: checkProfileUrlAction(true, false, true, "/pages-property-register"),
                        parentId: "properties",
                        click: () => checkProfileClickAction(true, false, true, "/pages-property-register")
                    },
                    {
                        id: "propertiesdetails",
                        label: "Visualizar propiedades",
                        link: checkProfileUrlAction(true, false, true, "/pages-property-lists"),
                        parentId: "properties",
                        click: () => checkProfileClickAction(true, false, true, "/pages-property-lists")
                    },
                ].filter(el => el)
            },
            user?.roles && [Role.Owner, Role.Agent, Role.Admin, Role.Tenant].includes(user?.roles[0]) && {
                id: "contract",
                label: "Contratos",
                icon: "ri-file-paper-2-line",
                link: "/contracts-register",
                stateVariables: isContract,
                click: function (e: any) {
                    e.preventDefault();
                    setIsContract(prev => !prev);
                    updateIconSidebar(e);
                },
                subItems: [
                    [Role.Owner, Role.Agent, Role.Admin].includes(user.roles[0]) && {
                        id: "contractform",
                        label: "Registrar contrato",
                        link: checkProfileUrlAction(true, true, true, "/contracts-register"),
                        parentId: "contract",
                        click: () => checkProfileClickAction(true, true, true, "/contracts-register")
                    },
                    {
                        id: "contractactive",
                        label: "Contratos activos",
                        link: checkProfileUrlAction(true, true, false, "/active-contracts-preview"),
                        parentId: "contract",
                        click: () => checkProfileClickAction(true, true, false, "/active-contracts-preview"),
                    },
                    {
                        id: "contract-inactive",
                        label: "Contratos inactivos",
                        link: checkProfileUrlAction(true, true, false, "/finished-contracts-preview"),
                        parentId: "contract",
                        click: () => checkProfileClickAction(true, true, false, "/finished-contracts-preview"),
                    },
                    {
                        id: "contractoffers",
                        label: "Ofertas de contratos",
                        link: checkProfileUrlAction(true, true, false, "/contracts-offers-preview"),
                        parentId: "contract",
                        click: () => checkProfileClickAction(true, true, false, "/contracts-offers-preview"),
                    },
                ].filter(el => el)
            },
            user?.roles && [Role.Owner, Role.Agent, Role.Admin, Role.Tenant].includes(user?.roles[0]) && {
                id: "payments",
                label: "Pagos",
                icon: " ri-secure-payment-line",
                link: "/pages-payments-lists",
                stateVariables: isPayments,
                click: function (e: any) {
                    e.preventDefault();
                    setIsPayments(prev => !prev);
                    updateIconSidebar(e);
                },

                subItems: [
                    {
                        id: "paymentshistory",
                        label: "Historial de pagos",
                        link: checkProfileUrlAction(true, true, false, "/payment-history"),
                        parentId: "payments",
                        click: () => checkProfileClickAction(true, true, false, "/payment-history"),
                    },
                    user?.roles && [Role.Owner, Role.Admin].includes(user?.roles[0]) ? {
                        id: "paymentsmethods",
                        label: "Métodos de pago",
                        link: checkProfileUrlAction(true, true, false, "/payment-methods"),
                        parentId: "payments",
                        click: () => checkProfileClickAction(true, true, false, "/payment-methods"),
                    } : null,
                    {
                        id: "accountState",
                        label: "Estado de cuenta",
                        link: checkProfileUrlAction(true, true, false, "/account-state"),
                        parentId: "payments",
                        click: () => checkProfileClickAction(true, true, false, "/account-state"),
                    },
                    {
                        id: "invoiceClaim",
                        label: "Reclamo de factura",
                        link: checkProfileUrlAction(true, true, false, "/invoice-claim"),
                        parentId: "payments",
                        click: () => checkProfileClickAction(true, true, false, "/invoice-claim"),
                    },
                ].filter(el => el)
            },
            /* {
                id: "agents",
                label: "Agentes",
                icon: "ri-customer-service-2-fill",
                link: "/pages-agents",
                click: function (e: any) {
                    e.preventDefault();
                    updateIconSidebar(e);
                },
            }, */
        ].filter(el => el);
    }, [checkProfileClickAction, checkProfileUrlAction, isContract, isFavoriteProperties, isPayments, isProperties, isUsers, user?.roles])

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;