import { FilePondFile } from "filepond";

export const fileToBinaryString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            // @ts-ignores
            resolve(reader.result.split(',')[1]); // Obtener solo la parte binaria de la base64
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};

export const generateFile = (buffer: ArrayBuffer, fileName: string, type: string) => {
    return new File([buffer], fileName, { type: type })
}

export const generateFiles = (files: {buffer: ArrayBuffer, fileName: string, type: string}[]) => {
    return files.map(({buffer, fileName, type}) => {
        return generateFile(buffer, fileName, type)
    })
}

export const generateFileFromFilePond = async (filePondFile: FilePondFile) => {
  return await Promise.resolve(filePondFile.file.arrayBuffer()).then((buffers) => {
        const file = generateFile(buffers, filePondFile.filename, filePondFile.fileType) 
        return file
    })
}

export const generateFilesFromFilePond = async (filePondFiles: FilePondFile[]) => {
    return await Promise.all(filePondFiles.map(filePondFile => generateFileFromFilePond(filePondFile)))
}

export const generateURLImage = (buffer: ArrayBuffer) => {
    return URL.createObjectURL(new Blob([buffer]))
}

export const generateURLSImages = (buffers: ArrayBuffer[]) => {
    return buffers.map(el => URL.createObjectURL(new Blob([el])))
}

export const generateURLImageFromFile = async (file: File) => {
    return await fileToBinaryString(file).then((base64) => {
        const arrayBuffer = Uint8Array.from(atob(base64), c => c.charCodeAt(0)).buffer
        const url = generateURLImage(arrayBuffer)
        return url
    })
}

export const generateURLImageFromFilePond = async (filePondFile: FilePondFile) => {
   return await Promise.resolve(filePondFile.file.arrayBuffer()).then((buffers) => {
        const url = generateURLImage(buffers)
        return url
    })
}

export const generateURLSImagesFromFilePond = async (filePondFiles: FilePondFile[]) => {
    return await Promise.all(filePondFiles.map(filePondFile => generateURLImageFromFilePond(filePondFile)))
}