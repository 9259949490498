import { appendFormWithFiles } from "helpers/appendFormWithFiles";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { IPayment, ICreatePayment, IUpdatePaymentStatus } from "types/api/payments";
const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};

export interface GetPaymentsQuery {
}


export const GetPayments = (filter: GetPaymentsQuery): Promise<IPayment[]> => api.get(`${url.GET_PAYMENTS}`, filter);
export const GetPayment = (id: string): Promise<IPayment> => api.get(`${url.GET_PAYMENT.replace("{id}", id)}`);
export const CreatePayment = (data: ICreatePayment): Promise<IPayment> => {
    const form = appendFormWithFiles(data, ['proof'])
    return api.create(url.POST_PAYMENTS, form, headers);
}
export const UpdatePaymentStatus = (id: string, data: IUpdatePaymentStatus): Promise<IPayment> => {
    return api.update(url.PATH_PAYMENT_STATUS.replace('{id}', id), data)
};

export const CreateAccountStatementPayment = (data: ICreatePayment): Promise<IPayment> => {
    const form = appendFormWithFiles(data, ['proof'])
    return api.create(url.POST_PAYMENTS_ACCOUNT_STATEMENT, form, headers);
}
export const UpdateAccountStatementPaymentStatus = (id: string, data: IUpdatePaymentStatus): Promise<IPayment> => {
    return api.update(url.PATH_PAYMENT_ACCOUNT_STATEMENT.replace('{id}', id), data)
};

export const DeletePayment = (id: string): Promise<void> => api.delete(url.DELETE_PAYMENT.replace('{id}', id));
