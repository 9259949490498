import React from 'react'
import { Button, ButtonProps } from 'reactstrap'
import { ScaleLoader } from 'react-spinners';

interface CustomButtonProps extends ButtonProps {
  loading?: boolean
}

export const CustomButton = (props: CustomButtonProps) => {
  return (
    <Button {...props} onClick={props.loading ? () => { } : props.onClick} disabled={props.loading || props.disabled} style={{
      position: 'relative'
    }}>
      <div
        style={{
          opacity: props.loading ? 0 : 1,
          transition: 'all .5s linear'
        }}
      >
        {
          props.children
        }
      </div>
      <div style={{
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        opacity: props.loading ? 1 : 0,
        transition: 'opacity 0.3s ease',
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {
          props.loading && <ScaleLoader height={20} />
        }
      </div>
    </Button>
  )
}
