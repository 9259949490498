import { parseDataToForm } from "helpers/pardeDataToForm";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { Pagination, PaginationResponse } from "types/api/common";
import { IClaim, ICreateClaim, IUpdateClaim } from "types/api/claim";
import { appendFormWithFiles } from "helpers/appendFormWithFiles";

const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};

export const createClaim = (data: ICreateClaim): Promise<IClaim> => {
    const form = appendFormWithFiles(data, ['invoiceProof', 'file', 'evidences']);   
    return api.create(url.CREATE_CLAIM, form, headers);
}

export const getClaims = (queryParameters: Pagination): Promise<PaginationResponse<IClaim>> =>
   api.get(url.GET_CLAIMS, queryParameters);

export const getClaimById = (id: string): Promise<IClaim> => 
    api.get(url.GET_CLAIM.replace('{id}', id));

export const updateClaim = (id: string, data: IUpdateClaim): Promise<IClaim> => {
    const form = appendFormWithFiles(data, ['invoiceProof', 'file', 'evidences']);
    return api.update(url.UPDATE_CLAIM.replace('{id}', id), form, headers);
} 
