import { Role } from "types/enums"
import { IContract } from "./contracts"

export enum AccountStatementStatus {
  PENDING = 'pending',
  PAID = 'paid',
  OVERDUE = 'overdue',
  APPROVED = 'approved',
  PENDING_APPROVAL = 'pendingApproval',
}

export enum TransactionType {
  INCOME = 'income',
  EXPENSE = 'expense',
}

export enum TransactionParty {
  TENANT = 'tenant',
  OWNER = 'owner',
}
export interface IAccountStatement {
  _id: string
  contract: IContract
  description: string
  amount: number
  creationDate: Date
  dueDate: Date
  status: `${AccountStatementStatus}`
  transactionType: `${TransactionType}`
  receiver: `${TransactionParty}`
  payer: `${TransactionParty}`
}

export interface ICreateAccountStatement {
  contract: string
  description: string
  amount: number
  creationDate: Date
  dueDate: Date
  status?: `${AccountStatementStatus}`
  transactionType?: `${TransactionType}`
}

export interface IUpdateAccountStatement extends ICreateAccountStatement {

}

export interface IGetUserAccountStatementQueryParameters {
  role: `${Role}`
}

export interface IGetAccountStatementTotalAmount {
  totalExpense: number
  totalIncome: number
}