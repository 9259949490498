import { parseDataToForm } from "helpers/pardeDataToForm";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { IAccountStatement, ICreateAccountStatement, IGetAccountStatementTotalAmount, IGetUserAccountStatementQueryParameters, IUpdateAccountStatement } from "types/api/account-statement";
import { Pagination, PaginationResponse } from "types/api/common";

const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};

export const createAccountStatement = (data: ICreateAccountStatement): Promise<IAccountStatement> =>
    api.create(url.CREATE_ACCOUNT_STATEMENT, data);

export const getAccountStatements = (queryParameters: Pagination & {contract: string}): Promise<PaginationResponse<IAccountStatement> & {totalBalance: number}> => 
    api.get(url.GET_ACCOUNT_STATEMENTS, queryParameters);

export const getTotalAmount = (): Promise<IGetAccountStatementTotalAmount> =>
    api.get(url.GET_ACCOUNT_STATEMENTS_TOTAL_AMOUNT);

export const getAccountStatementAmount = (): Promise<number> =>
    api.get(url.GET_ACCOUNT_STATEMENT_AMOUNT);

export const getUserAccountStatement = (queryParameters: IGetUserAccountStatementQueryParameters): Promise<IAccountStatement[]> =>
    api.get(url.GET_USER_ACCOUNT_STATEMENT, queryParameters);

export const getAccountStatement = (id: string): Promise<IAccountStatement> =>
    api.get(url.GET_ACCOUNT_STATEMENT.replace('{id}', id));

export const updateAccountStatement = (id: string, data: IUpdateAccountStatement): Promise<IAccountStatement> =>
    api.update(url.UPDATE_ACCOUNT_STATEMENT.replace('{id}', id), data);




export const RUN_CRON_MORATORIES = "/accounts-statement/cron-moratorias"
export const RUN_CRON_MENSUALIDADES = "/accounts-statement/cron-mensualidades"
