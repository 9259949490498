export const appendFormWithFiles = (data: any, filesRoute: string[]) => {
    const form = new FormData()
    for (const key in data) {
        if (filesRoute.includes(key)) {
            if(Array.isArray(data[key])) {
                for (const file of data[key]) {
                    form.append(key, file as File)
                }
            } else {
            form.append(key, data[key] as File)
            }
        } else {
            form.append(key, data[key])
        }
    }
    return form
}