import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Tooltip
} from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import * as Yup from "yup";
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FilePondFile } from 'filepond';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ICreateClaim } from 'types/api/claim';
import { useFormik } from 'formik';
import { generateFilesFromFilePond, generateURLImage, generateURLImageFromFile, generateURLSImagesFromFilePond } from 'helpers/file';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { GetContracts } from 'service/contracts';
import { ContractStatus } from 'types/api/contracts';
import Select from 'react-select';
import { Option } from '../../../../types/types';
import { createClaim } from 'service/claim';
import { toast } from 'react-toastify';
import { useAppSelector } from 'slices/hooks';
import { CustomButton } from 'Components/UI/CustomButton';
import { useNavigate } from 'react-router-dom';
import { parse } from 'path';

const formInitValue: ICreateClaim = {
    contract: "",
    amount: 0,
    description: "",
    evidences: [],
    invoiceProof: undefined,
    file: []
}

const GenerateInvoiceClaim = () => {
    document.title = "Reclamos de facturas | Partarentals";
    const { user } = useAppSelector(state => state.Profile)
    const navigate = useNavigate()
    const [offset, setOffset] = useState(0);
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [pageSize, setPageSize] = useState<number>(0)
    const [canNextPage, setCanNextPage] = useState<boolean>(true)
    const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const handleGetContractsApiCall = useHandleApiCall(GetContracts, {
        recallArguments: [{
            limit: 500,
            offset: 0,
            skip: 10,
        }],
    })
    const [selectedContract, setSelectedContract] = useState<Option | undefined>(undefined)
    const targetContract = useMemo(() => {
        return handleGetContractsApiCall.data?.data?.find((contract) => contract._id === selectedContract?.value)
    }, [selectedContract, handleGetContractsApiCall.data])
    const handleCreateClaimApiCall = useHandleApiCall(createClaim)
    const handleValidation = useFormik<ICreateClaim>({
        enableReinitialize: true,
        initialValues: formInitValue,
        onSubmit: tog_create_claim,
        validationSchema: Yup.object<ICreateClaim>({
            contract: Yup.string().required("Este campo es requerido"),
            amount: Yup.number().required("Este campo es requerido"),
            description: Yup.string().required("Este campo es requerido"),
            evidences: Yup.array(),
        }),
    })

    const handleAcceptedFiles1 = useCallback(async (newFiles: FilePondFile[]) => {
        if (handleValidation.values.evidences?.length === newFiles.length) return
        const _files = await generateFilesFromFilePond(newFiles)
        /* handleValidation.setFieldValue('evidences', _files) */
        handleValidation.setFieldValue('file', _files)
    }, [handleValidation])

    const handleReorderFiles1 = useCallback(async (newFiles: FilePondFile[]) => {
        const _files = await generateFilesFromFilePond(newFiles)
        /* handleValidation.setFieldValue('evidences', _files) */
        handleValidation.setFieldValue('file', _files)
    }, [handleValidation])

    const handleAcceptedFiles2 = useCallback(async (newFiles: FilePondFile[]) => {
        const _files = await generateFilesFromFilePond(newFiles)
        handleValidation.setFieldValue('invoiceProof', _files[0])
    }, [handleValidation])

    const pageOptions = useMemo(() => {
        return pageSize > 0 ? new Array(Math.ceil((pageSize / limit))).fill(null).map((el, i) => i) : []
    }, [limit, pageSize])

    const [pageIndex, setPageIndex] = useState<number>(0)
    const nextPage = () => {
        if (page < pageOptions.length - 1) {
            setPage(page + 1)
        }
    }
    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const gotoPage = (page: number) => {
        setPage(page)
    }

    const [modal_create_claim_success, set_modal_create_claim_success] = useState<boolean>(false);
    function tog_create_claim_success() {
        set_modal_create_claim_success(!modal_create_claim_success);
    }

    const [modal_create_claim, set_modal_create_claim] = useState<boolean>(false);
    function tog_create_claim() {
        set_modal_create_claim(!modal_create_claim);
    }

    const handleCreateClaim = useCallback(async (values: ICreateClaim) => {
        try {
            const response = await handleCreateClaimApiCall.refetch(values)
            if (response) {
                handleValidation.resetForm()
            }
            tog_create_claim_success()
            toast.success("Solicitud enviada con éxito")
        } catch (error) {
            toast.error("Error al enviar la solicitud")
        }
    }, [handleCreateClaimApiCall, handleValidation, tog_create_claim_success])

    useEffect(() => {
        handleGetContractsApiCall.refetch({
            limit: 10,
            offset: 0,
            skip: 10,
        })
    }, [user?.roles])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Reclamar pago de factura al arrendador" pageTitle="Pages" />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <Col lg={6} className="d-flex align-items-center mb-0 justify-content-start">
                                        <h4 className="card-title mb-0 flex-grow-1">Reclamar pago</h4>
                                    </Col>
                                </CardHeader>

                                <CardBody>

                                    <Row>
                                        <Col xs={12}>
                                            <div className="live-preview mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Label>
                                                                    Contrato asociado
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Select
                                                                    value={selectedContract}
                                                                    onChange={(selectedOption: any) => {
                                                                        setSelectedContract(selectedOption);
                                                                        handleValidation.setFieldValue('contract', selectedOption.value)
                                                                    }}
                                                                    options={handleGetContractsApiCall.data?.data?.map((contract) => {
                                                                        return {
                                                                            value: contract._id,
                                                                            label: contract._id
                                                                        }
                                                                    })}
                                                                    placeholder="Seleccione un contrato"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Label>
                                                                    Monto de la factura
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div className='mb-4'>
                                                                    <div className="input-group">
                                                                        <span className="input-group-text" id="basic-addon1">{targetContract?.currency.simbolo}</span>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="amount"
                                                                            name="amount"
                                                                            invalid={!!handleValidation.errors.amount && handleValidation.touched.amount}
                                                                            onBlur={handleValidation.handleBlur}
                                                                            value={handleValidation.values.amount ?? ""}
                                                                            min={0}
                                                                            onChange={(e) => {
                                                                                if (typeof parseFloat(e.target.value) === "number") {
                                                                                    const value = Math.abs(Number(e.target.value)) || ""
                                                                                    handleValidation.setFieldValue('amount', value)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        handleValidation.errors.amount &&
                                                                        handleValidation.touched.amount &&
                                                                        <Label className='text-danger'>
                                                                            <div>{handleValidation.errors.amount}</div>
                                                                        </Label>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={4}>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Label>
                                                                    Detalle del gasto realizado {" "}<i className="ri-information-line" id='ToolTip-1' ></i>
                                                                    <Tooltip
                                                                        isOpen={tooltipOpen}
                                                                        target="ToolTip-1"
                                                                        toggle={toggle}
                                                                    >
                                                                        Describa detalladamente el motivo del gasto a reclamar (Ejemplo: reparación de refrigerador)
                                                                    </Tooltip>
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    onReady={(editor) => {
                                                                        // You can store the "editor" and use when it is needed.

                                                                    }}
                                                                    onChange={(_, editor) => {
                                                                        const data = editor.getData();
                                                                        handleValidation.setFieldValue('description', data)
                                                                    }}
                                                                    config={{
                                                                        toolbar: []
                                                                    }}
                                                                />
                                                                {
                                                                    handleValidation.errors.description &&
                                                                    handleValidation.touched.description &&
                                                                    <Label className='text-danger'>
                                                                        <div>{handleValidation.errors.amount}</div>
                                                                    </Label>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row >

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="live-preview mb-2">
                                                <h4 className="card-title mb-0 flex-grow-1">Comprobante de factura</h4>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12}>
                                            <div className="live-preview mb-2">
                                                <p className='text-muted'>Adjuntar PDF de factura electrónica o fotografía si es factura fisica.</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='d-flex align-items-center mb-2'>
                                        <CardBody>
                                            <FilePond
                                                files={handleValidation.values.invoiceProof ? [handleValidation.values.invoiceProof] : []}
                                                onupdatefiles={handleAcceptedFiles2}
                                                allowMultiple={true}
                                                onreorderfiles={handleAcceptedFiles2}
                                                maxFiles={1}
                                                name="invoiceProof"
                                                id={"invoiceProof"}
                                                className="filepond filepond-input-multiple "
                                                allowReorder={true}
                                            />
                                        </CardBody>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="live-preview mb-2">
                                                <h4 className="card-title mb-0 flex-grow-1">Imágenes de evidencia <span className='text-muted'>{"(Opcional)"}</span></h4>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12}>
                                            <div className="live-preview mb-2">
                                                <p className='text-muted'>Adjuntar imágenes de evidencia de la reparación realizada.</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='d-flex align-items-center mb-2'>
                                        <CardBody>
                                            <FilePond
                                                files={handleValidation.values.file ? handleValidation.values.file : []}
                                                onupdatefiles={handleAcceptedFiles1}
                                                allowMultiple={true}
                                                onreorderfiles={handleReorderFiles1}
                                                maxFiles={10}
                                                name="file"
                                                id={"file"}
                                                className="filepond filepond-input-multiple "
                                                allowReorder={false}
                                            />
                                        </CardBody>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className='position-relative d-flex justify-content-end'>
                            <button
                                type='button'
                                className='btn btn-success d-flex align-items-center justify-content-center'
                                onClick={() => tog_create_claim()}
                            >
                                Enviar solicitud al arrendador
                            </button>
                        </Col>
                    </Row>
                </Container >
            </div >

            {/* Vertically Centered */}
            < Modal
                isOpen={modal_create_claim_success}
                toggle={() => {
                    tog_create_claim_success();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-party text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Su solicitud ha sido enviada al arrendador</h4>
                        <p className="text-muted mb-4">Una vez aprobada se deducirá dicho monto del saldo final en su estado de cuenta.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <CustomButton
                                color="light"
                                onClick={() => {
                                    navigate("/invoice-claim")
                                    set_modal_create_claim_success(false)
                                }}>Cerrar</CustomButton>
                        </div>
                    </div>
                </ModalBody>
            </ Modal>

            {/* Vertically Centered */}
            < Modal
                isOpen={modal_create_claim}
                toggle={() => {
                    tog_create_claim();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de enviar esta solicitud?</h4>
                        <form action="">
                            <div className="hstack gap-2 justify-content-center">
                                <CustomButton loading={handleCreateClaimApiCall.loading} color="light" onClick={tog_create_claim}>Cancelar</CustomButton>
                                <CustomButton loading={handleCreateClaimApiCall.loading} color="success" onClick={() => handleCreateClaim(handleValidation.values)}>Aceptar</CustomButton>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </ Modal>

        </React.Fragment >
    );
};

export default GenerateInvoiceClaim;